<template>
  <div id="app">
    <router-view class="bootstrap-wrapper" />
  </div>
</template>

<script>
var hours = 1; // Reset when storage is more than (1=60mins)(0.1=6mins)
var now = new Date().getTime();
var setupTime = localStorage.getItem('_setupTime_');
if (setupTime == null) {
  localStorage.setItem('_setupTime_', now)
} else {
  if (now - setupTime > hours * 60 * 60 * 1000) {
    // localStorage.clear()
    // localStorage.removeItem("_accountData_");
    // localStorage.removeItem("_memberData_");
    // .......................................
    localStorage.removeItem("_languages_");
    localStorage.removeItem("_countries_");
    // localStorage.removeItem("_vocal_type_");
    // localStorage.removeItem("_com_type_");
    // localStorage.removeItem("_demo_cat_");
    localStorage.setItem('_setupTime_', now);
  }
}

export default {
  name: 'App',
  beforeCreate() {
    if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
      console.log("%c It's a local server! ", 'background: #222; color: #bada55');
    } else {
      if (location.protocol !== 'https:') {
        location.replace(`https:${location.href.substring(location.protocol.length)}`);
      }
    }
  },
}
</script>

<style>
@import url(//fonts.googleapis.com/css?family=Raleway:300);

#app {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
}

.w-100 {
  width: 100% !important;
}

.text-center {
  align-items: center !important;
}

.cursor_pointer {
  cursor: pointer !important;
}

.swal-text {
  text-align: center !important;
}

.swal-footer {
  text-align: center !important;
}

.float-right {
  float: right !important;
}

.multiselect__tag {
  background: #3273dc !important;
}

.multiselect__tag-icon:after {
  color: #fff !important;
}

.multiselect__content {
  margin: 0 !important;
}

.show-mobile {
  display: none !important;
}

@media screen and (max-width: 767px) {

  /* (max-width: 600px) */
  .hidden-mobile {
    display: none !important;
  }

  .show-mobile {
    display: block !important;
  }
}

/* ................................................. */
.transform-none {
  text-transform: none !important;
}

.cut-text {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  /* display: none !important; */
  /* width: 160px;  */
  /* height: 1.2em;  */
}

.photo-album {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
  /* height: 175px !important; */
}

.photo-cover {
  width: 100%;
  height: 375px !important;
  object-fit: cover !important;
}

@media screen and (max-width: 992px) {

  /* (max-width: 767px) */
  .photo-cover {
    height: 275px !important;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal.show .modal-dialog {
  transform: none;
  border: 2px solid #024fa0;
  border-radius: 10px;
}

.modal.show {
  display: block !important;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.avatar-3xl {
  width: 245px !important;
  height: 245px !important;
}

.image-fit {
  object-position: center;
  object-fit: cover;
}

.two-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blur {
  background-color: rgba(245, 245, 245, 1);
  opacity: .01;
}

.swal-modal {
  border: 4px solid #024fa0;
  border-radius: 10px;
}

@media (min-width: 992px) {
  .mt-approval-info {
    margin-top: -2.5rem !important;
  }
}

@media (min-width: 576px) {
  .modal-mdx {
    max-width: 400px
  }
}
</style>
