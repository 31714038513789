import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

function lazyLoad(view) {
  return () => import(`@/views/${view}.vue`)
}

const routes = [
  {
    path: '/',
    name: 'Login',
    component: lazyLoad("Login")
  }, {
    path: '/logout',
    name: 'Logout',
    component: lazyLoad("Logout")
  }, {
    path: '/logout/:username/:password',
    name: 'Logout',
    component: lazyLoad("Logout")
  }, {
    path: '/forget_password/',
    redirect: '/',
  }, {
    path: '/forget_password/:code',
    name: 'ForgetPassword',
    component: lazyLoad("ForgetPassword")
  }, {
    path: '/register/',
    redirect: '/',
  }, {
    path: '/register/:c_code',
    name: 'Register',
    component: lazyLoad("Register")
  }, {
    path: '/Employee',
    component: lazyLoad("Employee/Base"),
    children: [
      {
        path: '',
        name: 'EmployeeProfile',
        component: lazyLoad("Employee/Profile")
      },
      {
        path: 'information',
        name: 'EmployeeInformation',
        component: lazyLoad("Employee/Information")
      },
      {
        path: 'password',
        name: 'EmployeePassword',
        component: lazyLoad("Employee/Password")
      },
      {
        path: 'company',
        name: 'EmployeeCompany',
        component: lazyLoad("Employee/Company")
      },
      {
        path: 'earning',
        name: 'EmployeeEarningDashboard',
        component: lazyLoad("Employee/EarningDashboard"),
      },
      {
        path: 'discover_more',
        name: 'EmployeeDiscoverMore',
        component: lazyLoad("Employee/DiscoverMore")
      },
      {
        path: 'support_center',
        name: 'EmployeeSupportCenter',
        component: lazyLoad("Employee/SupportCenter")
      },
      {
        path: 'send_link',
        name: 'EmployeeSendLink',
        component: lazyLoad("Employee/SendLink")
      },
      {
        path: 'send_link/email',
        name: 'EmployeeSendLinkEmail',
        component: lazyLoad("Employee/SendLinkEmail")
      },
      {
        path: 'send_link/text',
        name: 'EmployeeSendLinkText',
        component: lazyLoad("Employee/SendLinkText")
      },
    ]
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
