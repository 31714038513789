<script>
import moment from 'moment'
var md5 = require('md5');

import { employee } from './employeeData.js';

export default {
  name: "MainFunc",
  data() {
    return {
      needPassword: false,
      tokenPassword: "",
      loading_screen: true,
      screen_mode: "",
      gender: [
        { "id": 1, "name": "Male" },
        { "id": 2, "name": "Female" },
      ],
      rate: [100, 150, 200, 250, 300, 350, 400, 450, 500],
      age_range: [],
      union_status: [],
      delivery_cap: [],
      ethnicities: [],
      // languages: [],
      countries: [],
      vocal_type: [],
      // com_type: [],
      demo_cat: [],
      accents: [],
    }
  },
  // created(){
  //   this.age_range = this.LocalStorage("_age_range_",[]);
  //   this.union_status = this.LocalStorage("_union_status_",[]);
  //   this.delivery_cap = this.LocalStorage("_delivery_cap_",[]);
  //   this.ethnicities = this.LocalStorage("_ethnicities_",[]);
  //   this.languages = this.LocalStorage("_languages_",[]);
  //   this.countries = this.LocalStorage("_countries_",[]);
  //   this.vocal_type = this.LocalStorage("_vocal_type_",[]);
  //   this.com_type = this.LocalStorage("_com_type_",[]);
  //   this.demo_cat = this.LocalStorage("_demo_cat_",[]);
  //   this.accents = this.LocalStorage("_accents_",[]);
  // },
  methods: {
    LocalStorage(name, set = new Object()) {
      var taskArrayString = localStorage.getItem(name);
      if (taskArrayString !== null && typeof taskArrayString === "string") {
        // console.log("Succesfully retrieved 'tasks' and contents.");
      } else {
        // console.log("Succesfully retrieved 'tasks', contents were not a string or held no contents. Contents of 'tasks' reset to empty object");
        var emptyArray = JSON.stringify(set);
        localStorage.setItem(name, emptyArray);
        taskArrayString = localStorage.getItem(name);
      }
      return JSON.parse(taskArrayString);
    },
    alphabetically(array, field) {
      return array.sort(function (a, b) {
        if (a[field] < b[field]) { return -1; }
        if (a[field] > b[field]) { return 1; }
        return 0;
      })
    },
    sort_by_text_length(array) {
      return array.sort((a, b) => a.length - b.length);
    },
    set_session_employeeData(data) {
      let acc = {
        id: data.id,
        code: data.code,
        username: data.username,
        password: data.password,
        fname: data.fname,
        lname: data.lname,
        img: data.img,
        cover: data.cover,
        token: data.token
      }
      localStorage.setItem("_employeeData_", JSON.stringify(acc));
    },
    check_login(account) {
      // console.log("check_login", account, employee);
      if (account.code == employee.code) {
        if (account.token == employee.token) {
          this.screen_mode = 'active';
        } else {
          this.screen_mode = 'invalid_token';
        }
        setTimeout(() => { this.loading_screen = false; }, 1000);
      } else {
        // this.redirect("/");
        this.logout();
      }
    },
    md5(val) {
      return md5(val);
    },
    redirect(path) {
      if (this.$route.path !== path) this.$router.push(path)
    },
    r_com_type(company) {
      let text = company.com_type;
      if (this.com_type.length) {
        let index = this.com_type.findIndex((e) => parseInt(e.id) == parseInt(company.com_type));
        if (index > -1) return this.com_type[index].name;
      } else {
        return text;
      }
    },
    ask_for_logout() {
      this.$swal(`Are you sure you want to logout?`, ``, "warning", { buttons: ['Stay logged in', `Logout`], dangerMode: true })
        .then((ok) => {
          if (ok) {
            this.logout();
          }
        });
    },
    logout() {
      localStorage.setItem("_employeeData_", JSON.stringify(new Object));
      setTimeout(() => window.location.href = '/', 1);
    },
    uniq_id() {
      var n = Math.floor(Math.random() * 11);
      var k = Math.floor(Math.random() * 1000000);
      var m = String.fromCharCode(n) + k;
      return m;
    },
    updated_now() {
      var d = new Date,
        dformat = [d.getFullYear(),
        this.pad(d.getMonth() + 1, 2),
        this.pad(d.getDate(), 2)].join('-') + ' ' +
          [this.pad(d.getHours(), 2),
          this.pad(d.getMinutes(), 2),
          this.pad(d.getSeconds(), 2)].join(':');
      return dformat;
    },
    pad(num, size) {
      num = num.toString();
      while (num.length < size) num = "0" + num;
      return num;
    },
    generateRandomDigit(n = 10) {
      const characters = '0123456789';

      let result = '';
      for (let i = 0; i < n; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }

      return result
    },
    getFirstDateOfNextQuarter(inputDate) {
      // Parse the input date
      const currentDate = new Date(inputDate);

      // Calculate the current quarter
      const currentQuarter = Math.floor(currentDate.getMonth() / 3) + 1;

      // Calculate the first month of the next quarter
      const nextQuarterFirstMonth = currentQuarter * 3;

      // Calculate the first day of the next quarter
      const nextQuarterFirstDate = new Date(currentDate.getFullYear(), nextQuarterFirstMonth, 1);

      // Format the result as "YYYY-MM-DD"
      const formattedResult = `${nextQuarterFirstDate.getFullYear()}-${(nextQuarterFirstDate.getMonth() + 1).toString().padStart(2, '0')}-01`;

      return formattedResult;
    },
  },
  filters: {
    moment(date) {
      return moment(date).format('Do MMMM YYYY');
    },
    date_short: function (date) {
      return moment(date).format('D MMM') + " (PST)";
    },
    date_message: function (date) {
      return moment(date).format('D MMM, YYYY h:mm a');
    },
    date_project: function (date) {
      return moment(date).format('MMMM Do, YYYY h:mm a') + " (PST)";
    },
    date_approval: function (date) {
      return moment(date).format('D MMM, YYYY h:mm a') + " (PST)";
    },
    date_chat_message: function (date) {
      return moment(date).format('h:mm');
    },
    date_payout: function (date) {
      return moment(date).format('Do, MMMM YYYY');
    },
    date_list: function (date) {
      var fromNow = moment(date).fromNow();
      return moment(date).calendar(null, {
        lastWeek: '[Last] dddd',
        lastDay: '[Yesterday]',
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        sameElse: function () {
          return "[" + fromNow + "]";
        }
      });
    },
    highlight: function (words, query) {
      return words.replace(query, '<span class="highlight">' + query + '</span>')
    }
  }
};
</script>
